<template>
  <div class="col-md-6" id="profil1-app">
    <div class="h2 mt-3"> {{ $t('b2bein.profil-header') }}</div>


     <div class="row">

        <div class="col-md-4" >
          <div><small>logo</small></div>
          <figure class="company-logo">
            <img v-bind:src="me.logo_url" :key="logoKey"/>
          </figure>

          <button class="btn btn-sm btn-outline-secondary" type="button" v-on:click="fotochange()">{{$t("b2bein.profil.zmenit-logo")}}</button>
          <input type="file" id="file1" name='file1' ref="file1" v-on:change="onLogoChange" style="display: none"/>

        </div>


        <div class="col-md-8">
          <div class="form-group mb-3">
            <label class="semipopis">e-mail / username</label>
            <div class=" ">{{ me.email }}</div>
          </div>

          <div class="form-group mb-3">
            <label  class="semipopis">{{ $t('me.companyID')}}</label>
            <div class="">{{ me.companyID }}</div>
          </div>



          <div class="form-group" v-if="!editableCompany">
            <label  class="semipopis">{{$t('reg.companyname')}}</label>
            <div>{{ me.company.name }}</div>
          </div>

          <div class="form-group mb-0" v-else>
            <label for="inputName2" class="semipopis">{{$t('reg.companyname')}}</label>
            <input
                type="text"
                v-model.trim="$v.company.$model"
                @input="delayTouch($v.company)"
                class="form-control form-control-sm"
                id="inputName2"

            />
            <small
                class="form-text text-muted invisible mt-0"
                v-if="!$v.company.$dirty || !$v.company.$invalid"
            >Vyplňte své jméno</small>
            <small
                class="form-text text-danger mt-0"
                v-if="$v.company.$dirty && !$v.company.required"
            > {{$t('form.povinne-pole') }}  </small>


          </div>










          <div class="form-group">
            <label  class="semipopis">{{$t('profil.Verification')}}</label>
            <div class="h6">
            <div v-if="me.company_accepted_at==null" class="badge badge-danger">{{$t('profil.verification-no')}}</div>
            <div v-if="me.company_accepted_at" class="badge badge-success">{{$t('profil.verification-ok')}} </div>
            </div>
          </div>






          <div class="form-group mb-3">
            <label  class="semipopis">{{ $t('reg.company.category')}}</label>
            <div v-if="me.company">{{$t(me.company.category_name)}}</div>
          </div>

       <div class="form-group mb-0">
         <label for="inputName" class="semipopis">{{$t('reg.name')}}</label>
         <input
             type="text"
             v-model.trim="$v.name.$model"
             @input="delayTouch($v.name)"
             class="form-control form-control-sm"
             id="inputName"
             :placeholder="$t('reg.name')"
         />
         <small
             class="form-text text-muted invisible mt-0"
             v-if="!$v.name.$dirty || !$v.name.$invalid"
         >Vyplňte své jméno</small>
         <small
             class="form-text text-danger mt-0"
             v-if="$v.name.$dirty && !$v.name.required"
         > {{$t('form.povinne-pole') }}  </small>


       </div>















       <div class="form-group mb-0">
         <label for="inputAddress" class="semipopis">{{$t('reg.adresa')}}</label>
         <input
             type="text"
             v-model.trim="$v.address.$model"
             @input="delayTouch($v.address)"
             class="form-control form-control-sm"
             id="inputAddress"
             :placeholder="$t('reg.adresa')"
         />
         <small
             class="form-text text-muted invisible mt-0"
             v-if="!$v.address.$dirty || !$v.address.$invalid"
         >Vyplňte město a ulici</small>
         <small
             class="form-text text-danger mt-0"
             v-if="$v.address.$dirty && !$v.address.required"
         > {{$t('form.povinne-pole') }}  </small>
       </div>

       <div class="form-group mb-0">
         <label for="inputPhone" class="semipopis">{{$t('reg.telefon')}}</label>
         <input
             type="text"
             v-model.trim="$v.phone.$model"
             @input="delayTouch($v.phone)"
             class="form-control form-control-sm"
             id="inputPhone"
             :placeholder="$t('reg.telefon')"
         />
         <small
             class="form-text text-muted invisible mt-0"
             v-if="!$v.phone.$dirty || !$v.phone.$invalid"
         >Vyplňte telefon</small>
         <small
             class="form-text text-danger mt-0"
             v-if="$v.phone.$dirty && !$v.phone.required"
         > {{$t('form.povinne-pole') }}  </small>
       </div>

      <div class="form-group mb-0">
        <label for="inputCountry" class="semipopis">{{$t('reg.stat')}}</label>
        <input
            type="text"
            v-model.trim="$v.country.$model"
            @input="delayTouch($v.country)"
            class="form-control form-control-sm"
            id="inputCountry"
            :placeholder="$t('reg.stat')"
        />
        <small
            class="form-text text-muted invisible mt-0"
            v-if="!$v.country.$dirty || !$v.country.$invalid"
        >Zvolte stát</small>
        <small
            class="form-text text-danger mt-0"
            v-if="$v.country.$dirty && !$v.country.required"
        > {{$t('form.povinne-pole') }}  </small>
      </div>




       <button
           @click="updatuj($v)"
           class="btn btn-sm btn-secondary w-100 my-2"
       >{{ $t('save')}}</button>
       <div class="pt-2" v-if="msg.length > 0">
         <i class="fas fa-info-circle mr-2"></i>
         {{msg}}
         <div v-if="errors">
           <p :key="k" v-for="(e,k) in errors">{{k}} {{e}}</p>
         </div>
       </div>
     </div>









      </div>


  </div>

</template>

<script>


import userMixin from "@/mixins/userMixin";

const axios = require("axios");

import { required, email, sameAs } from "vuelidate/lib/validators";
const touchMap = new WeakMap();



export default {
  name: "Profil",
  mixins: [userMixin],

  data(){return  {
    logoKey:'12313',
    p: [], //window.user_profil,
    show_udaje: true,
    zeme: [],

    vizitka: false,
    editableCompany:true,


    email: "",

    country: "",
    address: "",
    company:'',
    phone: "",
    name: "",
    agree: false,
    msg: "",
    errors: []

  }
  },
  validations: {
    email: {
      required,
      email
      // isUnique(value) {
      //     // pro validaci musi byt platny email format
      //     if (!this.$v.reg.mail.email) return true

      //     return new Promise((resolve, reject) => {
      //         axios.get("/profil/check-email", {params:{email: value }}).then(response => {
      //             if (response.data.unique == 1){
      //                 //console.log('povoleni')
      //                 resolve(true)
      //             }else{
      //                 reject(false)
      //             }

      //         }).catch((error) => {
      //             reject(false)
      //         });
      //     })
      // }
    },

    country: {
      required
    },
    address: {
      required
    },
    name: {
      required
    },
    phone: {
      required
    },
    agree: {
      checked: sameAs(() => true)
    },
    company:{
       required
    },
    register: [
      "name",
      "country",
      "address",
      "phone",
      "company"
    ]
  },
  mounted(){
    while(!this.me){
      console.log('meme', this.me.id)
    }

    setTimeout(()=>{

        this.name= this.me.name
        this.country= this.me.country
        this.address=this.me.address

        if (this.me.company) {
          this.company = this.me.company.name
        }

        this.phone=this.me.tel

    }, 100)



  },

  computed: {
    me() {
      if (this.$store.getters.me){

        return this.$store.getters.me;
      }
      else
        return {}
    },

    btn_continue_class: function () {
      if (this.udaje_ok())
        return 'btn btn-primary'
      else
        return 'btn btn-outlok'
    }


  },
  components: {

  },

  methods: {

    udaje_ok:function(){
      var ok=true

      if (!this.p.www || this.p.www=="") ok=false
      if (!this.p.city|| this.p.city=="") ok=false
      if (!this.p.address|| this.p.address=="") ok=false
      if (!this.p.tel|| this.p.tel=="" ) ok=false

      if (this.$refs.tfx &&  this.$refs.tfx.phone.isValid==false) ok=false
      return ok
    },
    /*
          onTelInput ({ number, isValid, country }) {
            this.phone.number = number;
            this.phone.isValid = isValid;
            this.phone.country = country && country.name;

            console.log('input tel',number, isValid, country )
            if (!isValid) this.errors.tel="Invalid"
            else this.errors.tel=null
          },
    */

    _profilUpdate:function(){
      if (this.udaje_ok()) this.profilUpdate()
    },
    profilUpdate:function(){
      this.errors=[]
      axios.patch(process.env.VUE_APP_API_ROOT_URL+'/profil.json', {user_profil: this.p}, {
        headers: {
          'Authorization': `Bearer ${this.token}`
        }
      }).then(response => {
        if (response.data.status=='ok'){
          this.show_udaje=false
          this.vizitka=true
          window.location.href = '/profils/persons'
        }

      })
          .catch(error => {


                var data= error.response.data


                if (data.errors){
                  for(var i in data.errors) {
                    //this.errors = response.data.errors
                    var ee = ''
                    if (data.errors[i].length == 1){
                      ee = data.errors[i][0]
                    }else{
                      for(var j in data.errors[i]){
                        if (j>0 )ee+=", ";
                        ee+=   data.errors[i][j]
                      }
                    }


                    this.$set(this.errors, i, ee)
                  }

                }



              }
          )


    },

    fotochange: function () {
      document.getElementById('file1').click();
    },
    onLogoChange: function () {
      var file = this.$refs.file1.files[0];
      var formData = new FormData();
      formData.append('file', file);
      axios.post(process.env.VUE_APP_API_ROOT_URL+'/profils/logo', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${this.token}`


        }
      }).then(response => {
        console.log('SUCCESS!!', response.data.logo);
          if (response.status==200) {
            //this.logo = response.data.logo
            this.me.logo_url = response.data.logo
            this.logoKey = response.data.image.id
          }
        })
          .catch(function () {
            console.log('FAILURE!!');
          });


    },
    delayTouch($v) {
      $v.$reset();
      if (touchMap.has($v)) {
        clearTimeout(touchMap.get($v));
      }
      touchMap.set($v, setTimeout($v.$touch, 1000));
    },
    updatuj($v) {
      console.log('UPD', $v.register.$invalid)
      $v.register.$touch();
      if (!$v.register.$invalid) {

        var data={
          tel: this.phone,
          country: this.country,
          address: this.address,
          name: this.name,

        }
        if (this.editableCompany){
          data.company_name =  this.company
        }

        axios.put(
                process.env.VUE_APP_API_ROOT_URL + "/user", data )
            .then(result => {
              //console.log('reg', result.status, result.data)
              if (result.status == 200 && result.data.id > 0) {
                console.log("ok")
                this.$store.getters.me.name = this.name
                this.$store.getters.me.country = this.country
                this.$store.getters.me.address = this.address
                this.$store.getters.me.company_name = this.company


              } else {
                this.msg = "Chyba";
                this.errors = result.data.errors;
              }
            })
            .catch(err => {
              console.error("chyba", err);
            });
      }
    }
  }
}


</script>

<style scoped>
.semipopis{
  color: #555;
  margin-bottom: 1px;

}
</style>
